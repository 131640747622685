import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f2f62d16 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _41ac1bd2 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _092596f0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _b0fa0f3c = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _52993ff0 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _1636029a = () => interopDefault(import('../pages/realtime.vue' /* webpackChunkName: "pages/realtime" */))
const _d387e21a = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _6a916a9d = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _33034b5a = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "pages/topic/index" */))
const _49ba12b9 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _4954e3a6 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _7fde3ed8 = () => interopDefault(import('../pages/brands/meridank.vue' /* webpackChunkName: "pages/brands/meridank" */))
const _568777a6 = () => interopDefault(import('../pages/brands/sinyi.vue' /* webpackChunkName: "pages/brands/sinyi" */))
const _64cc36be = () => interopDefault(import('../pages/amp/article/_id.vue' /* webpackChunkName: "pages/amp/article/_id" */))
const _389e7109 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _3919fda1 = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _46f0a947 = () => interopDefault(import('../pages/series-sort/_id.vue' /* webpackChunkName: "pages/series-sort/_id" */))
const _71c05c42 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _47f3978e = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _751d1f18 = () => interopDefault(import('../pages/author/_role/_name.vue' /* webpackChunkName: "pages/author/_role/_name" */))
const _72063a1e = () => interopDefault(import('../pages/category/_mainCategory/_subCategory.vue' /* webpackChunkName: "pages/category/_mainCategory/_subCategory" */))
const _b386778c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _f2f62d16,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/brands",
    component: _41ac1bd2,
    meta: {},
    alias: ["/amp/brands"],
    name: "brands"
  }, {
    path: "/contact",
    component: _092596f0,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/member",
    component: _b0fa0f3c,
    meta: {},
    alias: ["/amp/member"],
    name: "member"
  }, {
    path: "/privacy",
    component: _52993ff0,
    meta: {},
    alias: ["/amp/privacy"],
    name: "privacy"
  }, {
    path: "/category/%E6%9C%80%E6%96%B0",
    component: _1636029a,
    meta: {},
    alias: ["/amp/category/%E6%9C%80%E6%96%B0"],
    name: "realtime"
  }, {
    path: "/search",
    component: _d387e21a,
    meta: {},
    alias: ["/amp/search"],
    name: "search"
  }, {
    path: "/sitemap",
    component: _6a916a9d,
    meta: {},
    alias: ["/amp/sitemap"],
    name: "sitemap"
  }, {
    path: "/topic",
    component: _33034b5a,
    meta: {},
    alias: ["/amp/topic"],
    name: "topic"
  }, {
    path: "/unsubscribe",
    component: _49ba12b9,
    meta: {},
    alias: ["/amp/unsubscribe"],
    name: "unsubscribe"
  }, {
    path: "/video",
    component: _4954e3a6,
    meta: {},
    alias: ["/amp/video"],
    name: "video"
  }, {
    path: "/brands/meridank",
    component: _7fde3ed8,
    meta: {},
    alias: ["/amp/brands/meridank"],
    name: "brands-meridank"
  }, {
    path: "/brands/sinyi",
    component: _568777a6,
    meta: {},
    alias: ["/amp/brands/sinyi"],
    name: "brands-sinyi"
  }, {
    path: "/amp/article/:id?",
    component: _64cc36be,
    meta: {"amp":true},
    alias: [],
    name: "amp-article-id"
  }, {
    path: "/article/:id?",
    component: _389e7109,
    meta: {},
    alias: ["/amp/article/:id?"],
    name: "article-id"
  }, {
    path: "/search/:q",
    component: _3919fda1,
    meta: {},
    alias: ["/amp/search/:q"],
    name: "search-q"
  }, {
    path: "/series-sort/:id?",
    component: _46f0a947,
    meta: {},
    alias: ["/amp/series-sort/:id?"],
    name: "series-sort-id"
  }, {
    path: "/topic/:id",
    component: _71c05c42,
    meta: {},
    alias: ["/amp/topic/:id"],
    name: "topic-id"
  }, {
    path: "/video/:id",
    component: _47f3978e,
    meta: {},
    alias: ["/amp/video/:id"],
    name: "video-id"
  }, {
    path: "/author/:role?/:name?",
    component: _751d1f18,
    meta: {},
    alias: ["/amp/author/:role?/:name?"],
    name: "author-role-name"
  }, {
    path: "/category/:mainCategory?/:subCategory?",
    component: _72063a1e,
    meta: {},
    alias: ["/amp/category/:mainCategory?/:subCategory?"],
    name: "category-mainCategory-subCategory"
  }, {
    path: "/",
    component: _b386778c,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
